<template>
  <div class="contact">
    <Header></Header>

    <v-container class="my-3">
      <v-row>
        <v-col cols="12">
          <h1 class="text-h2">Contact us</h1>
          <v-divider class="mt-8 mb-5"></v-divider>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12">
          <p class="body-1">
            Get in touch and let us know how we can help support your business.
          </p>
        </v-col>

        <v-col cols="12" sm="4">
          <v-card elevation="10" class="text-center">
            <v-avatar
              size="80"
              color="secondary"
              class="elevation-5 mt-4 rounded-circle"
            >
              <v-icon large color="white">mdi-handshake</v-icon>
            </v-avatar>
            <v-card-title class="justify-center">Sales</v-card-title>
            <v-card-text
              >We’d love to talk about how we can work together.</v-card-text
            >
            <v-divider></v-divider>

            <v-card-actions class="justify-center grey lighten-4">
              <v-btn
                text
                color="primary"
                href="mailto:support@mycoraapp.com?subject=CORA Sales Inquiry"
                target="_blank"
                >Contact Sales</v-btn
              >
            </v-card-actions>
          </v-card>
        </v-col>

        <v-col cols="12" sm="4">
          <v-card elevation="10" class="text-center">
            <v-avatar
              size="80"
              color="secondary"
              class="elevation-5 mt-4 rounded-circle"
            >
              <v-icon large color="white">mdi-lifebuoy</v-icon>
            </v-avatar>
            <v-card-title class="justify-center"
              >Help &amp; Support</v-card-title
            >
            <v-card-text
              >We’re here to help with any questions you have.</v-card-text
            >
            <v-divider></v-divider>

            <v-card-actions class="justify-center grey lighten-4">
              <v-btn
                text
                color="primary"
                href="mailto:support@mycoraapp.com?subject=CORA Support Inquiry"
                target="_blank"
                >Get support</v-btn
              >
            </v-card-actions>
          </v-card>
        </v-col>

        <v-col cols="12" sm="4">
          <v-card elevation="10" class="text-center">
            <v-avatar
              size="80"
              color="secondary"
              class="elevation-5 mt-4 rounded-circle"
            >
              <v-icon large color="white">mdi-email</v-icon>
            </v-avatar>
            <v-card-title class="justify-center"
              >General Communication</v-card-title
            >
            <v-card-text
              >Have some suggestions or just want to say hey?</v-card-text
            >
            <v-divider></v-divider>

            <v-card-actions class="justify-center grey lighten-4">
              <v-btn
                text
                color="primary"
                href="mailto:support@mycoraapp.com?subject=CORA General Inquiry"
                target="_blank"
                >General inquiry</v-btn
              >
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>
<script>
// @ is an alias to /src
import Header from "@/components/Header";

export default {
  components: {
    Header
  }
};
</script>
